import { useRoutes } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { protectedRoutes } from './private.routes';
import { publicRoutes } from './public.routes';
import downloadApp from '../assets/downloadApp.png';
import appOnAndroid from '../assets/appOnAndroid.png';
import appOnIos from '../assets/appOnIos.png';

export const AppRoutes = () => {
  const appTheme = useTheme();

  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));

  const { isAuthenticated } = useAuth();
  const routes = isAuthenticated ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);

  if (isMobile) {
    return (
      <Box
        style={{
          backgroundColor: '#7713D4',
          padding: '0px 8px',
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          flex: 1,
          flexDirection: 'column',
          margin: 0,
          overflowX: 'hidden',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1,
          }}
        />

        <Box
          style={{
            backgroundImage: `url(${downloadApp})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '432px',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            gap: '10px',
            alignItems: 'center',
            borderRadius: '16px',
            overflow: 'hidden',
            zIndex: 2, // Para garantir que fique acima do overlay
          }}
        >
          <Typography
            style={{ color: '#FFF', fontSize: '22px', fontWeight: 'bold' }}
          >
            Baixe nosso app
          </Typography>
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Button
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=br.com.lwsim.monitora.cliente&pcampaignid=web_share',
                  '_blank',
                );
              }}
              style={{
                backgroundImage: `url(${appOnAndroid})`,
                backgroundSize: 'contain',
                width: '120px',
                height: '40px',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <Button
              onClick={() => {
                window.open(
                  'https://apps.apple.com/br/app/simtrack/id6636496448',
                  '_blank',
                );
              }}
              style={{
                backgroundImage: `url(${appOnIos})`,
                backgroundSize: 'contain',
                width: '120px',
                height: '40px',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return <>{element}</>;
};
